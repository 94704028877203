<template>
  <b-card
    class="ecommerce-card mb-0 shadow-card h-100 relative dashboard-cards"
    no-body
  >
    <b-card-body>
      <div class="d-flex align-items-center justify-content-between">
        <b-badge
          pill
          variant="light-primary"
          class="mb-0"
        >
          {{ payload.created_time }}
        </b-badge>
        <a
          href="javascript: void(0);"
          class="d-flex wrap-dashboard-card-options text-dark"
          @click="printShopPdf"
        >
          <b-img
            :src="require('@/assets/images/app-icons/print.png')"
            class="mr-mobile-0"
          />
          <p class="mb-0 font-12 d-none-mobile">
            {{ $t('PRINT') }}
          </p>
        </a>
        <div
          v-if="payload.order_type"
          class="d-flex wrap-dashboard-card-options"
        >
          <b-img
            v-if="payload.order_type === 'DELIVERY'"
            class="mr-mobile-0"
            :src="require('@/assets/images/app-icons/agent.png')"
          />
          <b-img
            v-if="payload.order_type === 'DINE IN' || payload.order_type === 'DINE_IN'"
            class="mr-mobile-0"
            :src="require('@/assets/images/app-icons/shop.png')"
          />
          <b-img
            v-if="payload.order_type === 'POS'"
            class="mr-mobile-0"
            :src="require('@/assets/images/app-icons/POS.jpeg')"
          />
          <b-img
            v-if="payload.order_type === 'TAKEAWAY'"
            class="mr-mobile-0"
            :src="require('@/assets/images/app-icons/take-away.png')"
          />
          <p class="mb-0 font-12 d-none-mobile">
            {{ $helpers.replaceUnderscore(payload.order_type) }}
          </p>
        </div>
      </div>
      <p class="mb-0 font-14">
        {{ $t('Order No') }}: {{ payload.store_order_invoice_id }}
      </p>
      <div class="wrapper-order-cards">
        <table
          v-if="payload.invoice"
          class="table table-bordered table-hover mb-2 custom-slider"
          style="border-top: 0; border-left: 0"
        >
          <tbody>
            <tr
              v-for="(item, i) of payload.invoice.items"
              :key="i"
            >
              <td
                class="text-black pt-pb-5 px-1 w-100"
                colspan="3"
                :class="{ 'border-bottom': i != payload.invoice.items.length - 1 }"
              >
                <div class="d-grid grid-columns-template-wrapper">
                  <p class="mb-0">
                    {{ item.product.item_name }}
                  </p>
                  <p class="mb-0 text-right">
                    x {{ item.quantity }}
                  </p>
                  <p class="mb-0 text-right">
                    {{ item.total_item_price }}{{ payload.currency }}
                  </p>
                </div>
                <p
                  v-if="item.cartaddon && item.cartaddon.length > 0"
                  class="mb-0"
                >
                  ({{ item.cartaddon.length }} {{ $t('addons') }})
                </p>
                <div
                  v-if="item.cartaddon && item.cartaddon.length > 0"
                  class="addons-wrapper"
                >
                  <div
                    v-for="(addon, counter) of item.cartaddon"
                    :key="counter"
                    class="d-grid grid-columns-template-wrapper"
                  >
                    <p class="mb-0">
                      {{ addon.addon_name }}
                    </p>
                    <p class="mb-0 text-right">
                      x {{ item.quantity }}
                    </p>
                    <p class="mb-0 text-right">
                      {{ addon.addon_price }}{{ payload.currency }}
                    </p>
                  </div>
                </div>
                <p
                  v-if="getVariations(item.product_data).length > 0"
                  class="mb-0 mt-1 font-weight-bolder"
                >
                  {{ $t('Other details') }}
                </p>
                <ul
                  v-if="getVariations(item.product_data).length > 0"
                  style="padding-left: 15px"
                  class="mb-0"
                >
                  <li
                    v-for="(addon, count) of getVariations(item.product_data)"
                    :key="count"
                  >
                    {{ addon[0] }}: {{ addon[1] }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                class="text-right"
              >
                {{ $t('Total') }}: {{ payload.invoice.total_amount }}{{ payload.currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <p class="mb-0">
          {{ $t('Description') }}: <span
            v-if="payload.description"
            v-html="payload.description"
          />
          <span v-else>N/A</span>
        </p>
        <p class="mb-0">
          {{ $t('Status') }}: {{ payload.status }}
        </p>
        <b-media
          v-if="payload.user"
          class="border-bottom mt-2"
        >
          <template #aside>
            <b-avatar :src="$helpers.imageHelper(payload.user.picture)" />
          </template>
          <h6>{{ payload.user.first_name }} {{ payload.user.last_name }}</h6>
          <p class="mb-0">
            {{ $t('Mobile') }}: {{ payload.user.mobile }}
          </p>
          <p
            v-if="payload.delivery"
            class="mb-0"
            style="white-space: initial"
          >
            {{ $t('Address') }}: {{ payload.delivery.map_address }}
          </p>
        </b-media>
      </div>
      <div class="d-flex text-center mt-1 justify-content-between hidden-action-button-dashboard-cards">
        <b-button
          variant="primary"
          size="sm"
          @click="$emit('view-item', payload)"
        >
          {{ $t('View') }}
        </b-button>
        <b-button
          variant="success"
          size="sm"
          @click="
            $emit('update-request', {
              payload,
              status: 'ACCEPTED',
            })
          "
        >
          {{ $t('Accept') }}
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="
            $emit('update-request', {
              payload,
              status: 'CANCELLED',
            })
          "
        >
          {{ $t('Reject') }}
        </b-button>
      </div>
      <div class="d-flex text-center mt-1 justify-content-between action-button-dashboard-cards">
        <b-button
          variant="primary"
          size="sm"
          @click="$emit('view-item', payload)"
        >
          {{ $t('View') }}
        </b-button>
        <b-button
          variant="success"
          size="sm"
          @click="
            $emit('update-request', {
              payload,
              status: 'ACCEPTED',
            })
          "
        >
          {{ $t('Accept') }}
        </b-button>
        <b-button
          v-if="showRejectButton"
          variant="danger"
          size="sm"
          @click="
            $emit('update-request', {
              payload,
              status: 'CANCELLED',
            })
          "
        >
          {{ $t('Reject') }}
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BBadge, BMedia, BButton, BAvatar, BImg } from 'bootstrap-vue'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader } from '@/@core/comp-functions/ui/app'
import { useStoreUi } from '../useStore'

export default {
  components: {
    BCard,
    BCardBody,
    BBadge,
    BMedia,
    BButton,
    BAvatar,
    BImg,
  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
    showRejectButton: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    invoiceUrl() {
      if (this.payload.invoice && this.payload.invoice.invoice_url) {
        return `${jwtDefaultConfig.baseEndpoint}${this.payload.invoice.invoice_url}`
      }
      return ''
    },
  },
  methods: {
    getVariations(products) {
      if (!products) return []
      try {
        return Object.entries(JSON.parse(products))
      } catch (error) {
        return []
      }
    },
    printShopPdf() {
      const { printShopPdf } = useStoreUi()
      showLoader()
      printShopPdf(this.payload.id)
        .then(({ data }) => {
          hideLoader()
          if (data) {
            window.open(URL.createObjectURL(data))
          } else {
            showDangerNotification(this, 'Failed to download invoice')
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style scoped>
.wrap-dashboard-card-options {
  border: 1px solid #ddd;
  padding: 2px 5px;
  border-radius: 4px;
}

.wrapper-order-cards {
  max-height: 300px;
  overflow-y: auto;
}

.grid-columns-template-wrapper {
  grid-template-columns: 50% 30% 20%;
  display: grid;
}

.wrap-dashboard-card-options img {
  height: 20px;
  margin-right: 5px;
}

.action-button-dashboard-cards {
  position: absolute;
  bottom: 15px;
  width: 91%;
}

.hidden-action-button-dashboard-cards {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.dashboard-cards {
  padding-bottom: 0;
}
</style>
